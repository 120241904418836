import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { A, navigate, usePath } from "hookrouter";
import React, { useState } from "react";
import logo from "../common/images/logov3transparent.png";
import { Transition } from "@tailwindui/react";
export default function NavBar() {
  const [navOpen, setNavOpen] = useState(false);

  const ieeelinks = [
    {
      link: "https://www.ieee.org/Loginforms/myIEEE/login.php",
      title: "MyIEEE",
    },
    {
      link: "https://www.ieee.org/",
      title: "IEEE.Org",
    },
    {
      link: "https://ieeexplore.ieee.org/Xplore/home.jsp",
      title: "IEEE Xplore Digital Library",
    },
    {
      link: "https://spectrum.ieee.org/",
      title: "IEEE Spectrum",
    },
    {
      link: "https://www.ieeer10.org/",
      title: "IEEE Region 10",
    },
    {
      link: "https://ieeekerala.org/",
      title: "IEEE Kerala Section",
    }
  ];
  const links = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/allEvents",
      title: "Events",
    },
    {
      link:"https://pegasus.ieeesbmits.in/",
      title: "PEGASUS"
    },
    {
      link: "/achievements",
      title: "Achievements",
    },
    {
      link: "/behindUs",
      title: "Behind Us",
    },
    {
      link:"https://ras.ieeesbmits.in/",
      title: "R.A.S"
    },
  ];
  const path = usePath();
  const url = path === "/" ? "/home" : path;

  return (
    <div className="w-full flex flex-wrap relative ">
      <div className="inset-x-0 h-20 w-full"></div>
      <div className="w-full absolute top-0 left-0  flex flex-wrap justify-center">
        <nav className="shadow-md mx-auto  w-full   border-t  border-gray-200 text-gray-800 bg-white lg:mx-auto max-w-7xl  z-10 rounded-lg mt-1">
          <div className="hidden md:flex justify-between border-b border-blue-600 text-xs py-1">
            {ieeelinks.map((el, i) => {
              return (
                <a
                  className="my-1  font-semibold hover:text-blue-700 mx-4"
                  rel="noopener noreferrer"
                  href={el.link}
                  target="_blank"
                >
                  {el.title}
                </a>
              );
            })}
          </div>
          <div className=" w-full mx-auto px-6 py-2 md:flex md:justify-between md:items-center rounded-b-lg">
            <div className="flex justify-between items-center">
              <div>
                <A
                  className="text-gray-800 text-xl font-bold md:text-2xl hover:"
                  href="/"
                >
                  <img src={logo} alt="" className="h-10 object-contain" />
                </A>
              </div>

              <div className="flex md:hidden">
                <button
                  className="focus:outline-none outline-none cursor-default sm:cursor-pointer rounded-full hover:bg-gray-100 transition-color duration-100 w-8 h-8 item-center justify-center"
                  onClick={(_) => setNavOpen(!navOpen)}
                  aria-label="menu"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
              </div>
            </div>

            <div className="hidden md:flex items-center">
              <div className="flex-row ">
                {links.map((el, i) => {
                  const link = el.link === "/" ? "/home" : el.link;
                  // if ()
                  const isActive =
                    el.link == "/allEvents" && url.includes("events")
                      ? true
                      : url.includes(link);
                  return (
                    el.title=='R.A.S'?
                    <a
                      className={`my-1 font-semibold px-3 py-2 text-sm  rounded-full hover:text-blue-700 md:mx-4 md:my-0 ${
                        isActive ? " bg-blue-50 text-blue-700" : "text-black"
                      }`}
                      rel="noopener noreferrer"
                      href={el.link}
                      target="_blank"
                    > {el.title}
                    </a>
                    :
                    <A
                      className={`my-1 font-semibold px-3 py-2 text-sm  rounded-full hover:text-blue-700 md:mx-4 md:my-0 ${
                        isActive ? " bg-blue-50 text-blue-700" : "text-black"
                      }`}
                      href={el.link}
                    >
                      {el.title}
                    </A>
                  );
                })}
              </div>
            </div>
          </div>
        </nav>
        <div className="w-full z-40">
          <Transition
            show={navOpen}
            enter="transition origin-top-right ease-in-out duration-300 transform"
            enterFrom="scale-0"
            enterTo="scale-100"
            leave="transition origin-top-right ease-in-out duration-300 transform"
            leaveFrom="scale-100"
            leaveTo="scale-0 "
          >
            <div className="md:hidden bg-white mt-1 rounded-lg shadow-lg">
              <div className="flex flex-col ">
                {links.map((el, i) => {
                  const link = el.link === "/" ? "/home" : el.link;
                  const isActive = url.includes(link);
                  return (
                    el.title=='R.A.S'?
                    <a
                      class={`py-2 text-lg relative rounded-lg font-medium hover:text-blue-700 md:mx-4 md:my-0 text-center${
                        isActive ? "text-blue-700 bg-blue-50" : ""
                      }`}
                      rel="noopener noreferrer"
                      href={el.link}
                      target="_blank"
                    > {el.title}
                    </a>
                    :
                    <button
                      key={i}
                      class={`py-2 text-lg relative rounded-lg font-medium hover:text-blue-700 md:mx-4 md:my-0 ${
                        isActive ? "text-blue-700 bg-blue-50" : ""
                      }`}
                      onClick={() => {
                        navigate(el.link);
                        setNavOpen(false);
                        
                      }}
                    >
                      {el.title}
                    </button>
                  );
                })}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
}
